<template>
  <div>
    <footer class="footer">

      <div class="container-fluid">
        <div class="row text-center text-md-left">
          <div class="col-12 col-lg">
            <p class=" m-0">
              <strong>
                © <span v-text=" new Date().getFullYear() "></span> Ecart. All Rights Reserved
              </strong>
              <br>
              <span v-for=" (link,index) in links " :key=" index ">
                <router-link :to="link.link" v-text=" link.title " :target=' link.target '
                  v-if=" link.target == '_self'  "> </router-link>
                <a :href="link.link" :target=' link.target ' v-text=" link.title " v-else> </a>
                <strong v-text=" ( (index < links.length -1 ) ? ' - ':'' )  "></strong>
              </span>
            </p>
          </div>
          <div class="col-12 col-lg-auto">
            <div class="row justify-content-center">
              <div class="col-auto px-1">
                <a href="https://www.instagram.com/seller.ecart" target="_blank">
                  <img src="@/assets/images/icons/icon_instagram.svg" class="img-contain-20" alt="">
                </a>
              </div>
              <div class="col-auto px-1">
                <a href="https://www.facebook.com/ecart.marketplace" target="_blank">
                  <img src="@/assets/images/icons/icon_facebook.svg" class="img-contain-20" alt="">
                </a>
              </div>
              <div class="col-auto px-1">
                <a href="https://www.youtube.com/channel/UCTXkvi3lazCDafPazt58Szw" target="_blank">
                  <img src="@/assets/images/icons/icon_youtube.svg" class="img-contain-20" alt="">
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
  export default {
    name: 'footerPage',
    data() {
      return {
        links: [{
            title: this.$t('components.footer.links.policy'),
            link: "/privacy-policy",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.terms'),
            link: "/terms-&-conditions",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.faqs'),
            link: "/faqs",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.returns'),
            link: "/returns-and-cancellations",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.ecart'),
            link: "https://ecart.com",
            target: '_blank'
          },
          {
            title: this.$t('components.footer.links.promotions'),
            link: "/promotions",
            target: '_self'
          },
        ],
      }
    }
  }
</script>